import { GetTextEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage, useSessionStorage } from '@/hooks/useStorage';
import {
  emailRegisterApi,
  getPrivacyAgreementApi,
  phoneRegisterApi,
  sendMailApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { history, useModel } from '@umijs/max';
import { useCountDown, useMount } from 'ahooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

import ProjectSetting from '@/setting/projectSetting';

/**
 *
 *  username  用户账户（手机号/邮箱号）
 captcha  验证码
 password  密码
 invitationCode  邀请码
 */

export enum WAY_TO_REGISTER {
  // 手机号注册
  PHONE = '0',
  // 邮箱注册
  EMAIL = '1',
}

export default function useStateHooks() {
  const { appInfo = {} } = useModel('appInfo');
  const { AUTH_REGISTER_CAPTCHA = true, AUTH_REGISTER_CAPTCHA_PHONE = true } =
  appInfo || {};
  const { t } = useTranslation();
  //是否缓存数据
  const [urlState] = useUrlState<any>({});
  const { isKeepAlive, invite: _invite, inviteCode, currentRegister: defaultCurrentRegister } = urlState || {};
  // 注册方式选择和当前选中的注册方式
  const [registerOption] = useState([
    {
      value: WAY_TO_REGISTER.EMAIL,
      text: t('邮箱'),
    },
    {
      value: WAY_TO_REGISTER.PHONE,
      text: t('手机号'),
    },
  ]);
  const [currentRegister, setCurrentRegister] = useState(defaultCurrentRegister ?? WAY_TO_REGISTER.EMAIL);
  //获取隐私协议和用户协议
  const { data: privacyAgreement, run: getPrivacyAgreement } = useReq(
    getPrivacyAgreementApi,
    {
      manual: true,
    },
  );
  const { data: userAgreement, run: getUserAgreement } = useReq(
    getPrivacyAgreementApi,
    {
      manual: true,
    },
  );
  useMount(() => {
    getPrivacyAgreement({
      type: GetTextEnum.PRIVACY_CLAUSE_CODE,
    });
  });
  useMount(() => {
    getUserAgreement({
      type: GetTextEnum.SYSTEM_USER,
    });
  });
  // 注册手机号
  const [targetDate, setTargetDate] = useLocalStorage({
    key: CacheEnum.APP_REGISTER_CODE,
    defaultValue: 0,
  });
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
    },
  });
  // 注册邮箱
  const [targetDateEmail, setTargetDateEmail] = useLocalStorage({
    key: CacheEnum.APP_REGISTER_CODE_EMAIL,
    defaultValue: 0,
  });
  const [countdownEmail] = useCountDown({
    targetDate: targetDateEmail,
    onEnd: () => {
    },
  });

  const invite = _invite || inviteCode;
  //表单
  const [registerForm, setRegisterForm] = useSessionStorage({
    key: CacheEnum.APP_REGISTER_CACHE,
    defaultValue: {
      email: '',
      captcha: '',
      password: '',
      repeatPassword: '',
      phone: '',
      invitationCode: invite ?? '',
      checked: true,
    },
  });
  //是否显示区号
  const [isShowAreaCode, setIsShowAreaCode] = useState(false);
  useMount(() => {
    if (!isKeepAlive) {
      setRegisterForm({
        email: '',
        captcha: '',
        password: '',
        repeatPassword: '',
        phone: '',
        invitationCode: invite ?? '',
        checked: true,
      });
    } else {
      setIsShowAreaCode(true);
    }
  });

  //发送邮件或者手机验证码
  const { run: sendMailApiRequest, loading: sendMailLoading } = useReq(sendMailApi, {
    manual: true,
    loadingDefault: false,
    onSuccess: (res) => {
      if (currentRegister === WAY_TO_REGISTER.PHONE) {
        setTargetDate(Date.now() + (30000));
      } else {
        setTargetDateEmail(Date.now() + (30000));
      }
      toast.success(t('发送成功'));
    },
    onError() {
      toast.error(t('发送失败'));
      if (currentRegister === WAY_TO_REGISTER.PHONE) {
        setTargetDate(Date.now());
      } else {
        setTargetDateEmail(Date.now());
      }
    },
  });


  //去登录
  const goLogin = () => {
    history.push(PageEnum.LOGIN);
  };

  //请求token
  const [, setToken] = useLocalStorage({
    key: CacheEnum.AUTHORIZATION,
  });

  //刷新token
  const [, setRefreshToken] = useLocalStorage({
    key: CacheEnum.REFRESHTOKEN,
  });

  const { isMobile } = useModel('system');
  const { fundingOverviewSocketMapReq, requestUserInfo } = useModel('user');


  const login = async (data: any, userName: any) => {
    try {
      toast.success(t('注册成功！正在为您自动登录'));
      //添加cookie
      Cookies.set(CacheEnum.USER_TOKEN, userName, {
        expires: 30,
      });
      //使用crypto-js进行加密
      Cookies.set(
        CacheEnum.USER_PASSWORD,
        CryptoJS.AES.encrypt(registerForm.password, ProjectSetting.APP_AES_KEY),
        {
          expires: 30, // 存储30天
        },
      );
      /*
         * 保存token等用户信息,跳转登录
         * */
      localStorage.setItem(CacheEnum.APP_LOGIN_TYPE, '2');
      setToken(data?.accessToken);
      if (data?.refreshToken) {
        setRefreshToken(data?.refreshToken);
      }
      fundingOverviewSocketMapReq?.();

      localStorage.setItem(CacheEnum.APP_USER_NAME, userName!);
      requestUserInfo?.();

      if (!isMobile) {
        history.push(PageEnum.PCHOME);
        return;
      }
      history.push(PageEnum.HOME);
    } catch (e) {
      toast.success(t('注册成功！正在为您自动登录'));
      setToken(data?.accessToken);
      if (data?.refreshToken) {
        setRefreshToken(data?.refreshToken);
      }
      localStorage.setItem(CacheEnum.APP_LOGIN_TYPE, '2');
      localStorage.setItem(CacheEnum.APP_USER_NAME, userName!);

      requestUserInfo?.();

      if (!isMobile) {
        history.push(PageEnum.PCHOME);
        return;
      }
      history.push(PageEnum.HOME);
    }
  };

  //手机号注册
  const { run: registerRequest, loading: submitLoading } = useReq(
    phoneRegisterApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: (res) => {
        login(res, registerForm.phone);

      },
    },
  );
  //邮箱号注册
  const { run: registerMailRequest, loading: submitMailLoading } = useReq(
    emailRegisterApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: (res) => {
        login(res, registerForm.phone);
      },
    },
  );
  const { currentAreaCode } = useModel('areaCode');

  // 是否显示验证码
  const isShowCaptcha = useMemo(() => {
    return (
      (currentRegister === WAY_TO_REGISTER.PHONE &&
        AUTH_REGISTER_CAPTCHA_PHONE) ||
      (currentRegister === WAY_TO_REGISTER.EMAIL && AUTH_REGISTER_CAPTCHA)
    );
  }, [currentRegister, AUTH_REGISTER_CAPTCHA_PHONE, AUTH_REGISTER_CAPTCHA]);
  //提交
  const submitRegister = () => {
    if (!registerForm?.email?.replace(/\s+/g, '') && currentRegister === WAY_TO_REGISTER.EMAIL)
      return toast.error(t('请输入邮箱'));
    if (!registerForm?.phone?.replace(/\s+/g, '') && currentRegister === WAY_TO_REGISTER.PHONE)
      return toast.error(t('请输入手机号'));
    if (!registerForm?.captcha && isShowCaptcha)
      return toast.error(t('请输入验证码'));
    if (!registerForm?.password?.replace(/\s+/g, '')) return toast.error(t('请输入密码'));
    if (!registerForm?.repeatPassword?.replace(/\s+/g, '')) return toast.error(t('请再次输入密码'));
    if (registerForm?.password?.replace(/\s+/g, '') !== registerForm?.repeatPassword?.replace(/\s+/g, ''))
      return toast.error(t('两次输入密码不一致'));
    if (appInfo?.MEMBER_CODE_REQUIRED && !registerForm?.invitationCode) return toast.error(t('请输入邀请码'));
    if (currentRegister === WAY_TO_REGISTER.PHONE) {
      registerRequest({
        ...registerForm,
        phone: registerForm.phone?.replace(/\s+/g, ''),
        email: registerForm.email?.replace(/\s+/g, ''),
        password: registerForm.password?.replace(/\s+/g, ''),
        areaCode: (currentAreaCode as any)?.areaCode,
      });
    }
    if (currentRegister === WAY_TO_REGISTER.EMAIL) {
      registerMailRequest({
        ...registerForm,
        phone: registerForm.phone?.replace(/\s+/g, ''),
        email: registerForm.email?.replace(/\s+/g, ''),
        password: registerForm.password?.replace(/\s+/g, ''),
        areaCode: (currentAreaCode as any)?.areaCode?.replace(/\s+/g, ''),
      });
    }
  };

  return {
    sendMailApiRequest, // 发送验证码请求
    countdown,
    privacyAgreement,
    submitLoading,
    userAgreement,
    registerForm,
    setRegisterForm,
    submitRegister,
    goLogin,
    isShowAreaCode,
    setIsShowAreaCode,
    registerOption,
    currentRegister,
    setCurrentRegister,
    isShowCaptcha,
    setTargetDate,
    targetDateEmail,
    setTargetDateEmail,
    countdownEmail,
    sendMailLoading,
  };
}
export type RegisterType = ReturnType<typeof useStateHooks>;
